<template>
  <v-card flat tile :loading="loading">
    <v-toolbar dense flat>
      <TermTabs v-model="term" centered />
    </v-toolbar>

    <v-list>
      <Course
        v-for="course in courses"
        v-bind:key="course.id"
        :value="course"
      />
    </v-list>
  </v-card>
</template>

<script>
import { saveChildRoute } from '@/router/helper';
import Course from '@/components/Course';
import TermTabs from '@/components/TermTabs';

export default {
  name: 'PersonCourses',
  components: {
    Course,
    TermTabs,
  },
  props: {
    id: { type: String },
  },
  computed: {
    personId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      courses: [],
      term: null,
      loading: false,
    };
  },
  methods: {
    async getData() {
      if (this.personId && this.term) {
        this.loading = true;
        this.courses = await this.apiList({
          resource: 'course/course',
          query: `person=${this.personId}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
        });
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.getData();
    },
    async term() {
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
};
</script>
